import { Form, Formik } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';
import { string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import BEMHelper from 'react-bem-helper';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { resolve } from '../../../helpers/urls';
import { postForm } from '../../../helpers/netlify';
import { Button } from '../../common/button';
import { Content } from '../../common/content';
import {
  Checkbox, Input, Textarea, Select, Countryselect,
} from '../../common/form';
import './contact.scss';

const bem = new BEMHelper({
  name: 'content-contact',
});

const query = graphql`
  query {
    response: craftgql {
      contact: globalSet(handle: "contact") {
        ...on CraftGQL_contact_GlobalSet {
          privacyterms: contactPrivacyTermsPage {
            ...on CraftGQL_pages_pages_Entry {
              title
              slug: pageSlug
              departments: pageDepartments {
                ...on CraftGQL_departments_department_Entry {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () => Yup.object().shape({
      firstname: Yup
        .string()
        .min(3, t('Your first name should contain at least 3 letters.'))
        .required(t('Your first name is required.')),
      lastname: Yup
        .string()
        .min(3, t('Your last name should contain at least 3 letters.'))
        .required(t('Your last name is required.')),
      email: Yup
        .string()
        .email(t('Your E-Mail address is invalid.'))
        .required(t('Your E-Mail address is required.')),
      phone: Yup
        .string()
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          t('Your phone number is invalid.'),
        ),
      country: Yup
        .string()
        .required(t('Please select a country.')),
      city: Yup
        .string()
        .required(t('Please enter a city.')),
      message: Yup
        .string()
        .required('A message is required.'),
      privacy: Yup
        .boolean()
        .oneOf(
          [true],
          t('You need to accept our privacy terms.')),
    }),
    [],
  );
};

const useSubmit = (subject) => useCallback((values, { setStatus, setSubmitting }) => {
  const submit = async () => {
    try {
      await postForm(subject, values);
      setStatus({ success: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
    }
  };
  submit();
}, [subject]);

export const Contact = ({ id, headline, subject }) => {
  const { response } = useStaticQuery(query);
  const { contact } = response;
  const { t } = useTranslation();
  const schema = useSchema();
  const onSubmit = useSubmit(subject);

  const privacytermsLink = resolve('pages:detail', {
    pageSlug: contact.privacyterms[0].slug,
    departmentSlug: contact.privacyterms[0].departments[0]?.slug,
  });

  return (
    <Content id={id} {...bem()}>
      <h2 {...bem('headline')}>{headline}</h2>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          company: '',
          country: '',
          region: '',
          city: '',
          activity: '',
          email: '',
          phone: '',
          message: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          status,
          touched,
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
        }) => (
          <>
            {status?.success
              ? (
                <p {...bem('success')}>{t('Thank you, we received your message.')}</p>
              )
              : (
                <Form
                  {...bem('form', { 'is-submitting': isSubmitting })}
                  name={subject}
                  method="POST"
                  data-netlify="true"
                >
                  <div {...bem('fifty-fifty')}>
                    <Input
                      label={t('First Name') + '*'}
                      type="text"
                      name="firstname"
                      id={`${id}-firstname`}
                      value={values.firstname}
                      error={errors.firstname && touched.firstname && errors.firstname}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      halfSize={true}
                      required
                    />
                    <Input
                      label={t('Last Name') + '*'}
                      type="text"
                      name="lastname"
                      id={`${id}-lastname`}
                      value={values.lastname}
                      error={errors.lastname && touched.lastname && errors.lastname}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      halfSize={true}
                      required
                    />
                  </div>

                  <Input
                    label={t('Company')}
                    type="text"
                    name="company"
                    id={`${id}-company`}
                    value={values.company}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  />

                  <Countryselect
                    label={t('Country of Operation') + '*'}
                    name="country"
                    id={`${id}-country`}
                    value={values.country}
                    region={values.region}
                    error={errors.country && touched.country && errors.country}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />

                  <Input
                    label={t('City') + '*'}
                    type="text"
                    name="city"
                    id={`${id}-city`}
                    value={values.city}
                    error={errors.city && touched.city && errors.city}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    required
                  />

                  <Select
                    label={t('Interested in')}
                    name="activity"
                    id={`${id}-activity`}
                    value={values.activity}
                    options={[
                      t('SkinCare Brands'),
                      t('SkinCare Private Label'),
                      t('MedCare'),
                      t('MatriDerm®'),
                      t('ProHeal®'),
                      t('Dr. Kitzinger®'),
                      t('reveel by MedSkin Solutions®'),
                    ]}
                    defaultValue={t('Select field of interest')}
                    error={errors.activity && touched.email && errors.activity}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  />

                  <Input
                    label={t('Business E-Mail') + '*'}
                    type="email"
                    name="email"
                    id={`${id}-email`}
                    value={values.email}
                    error={errors.email && touched.email && errors.email}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    required
                  />

                  <Input
                    label={t('Phone')}
                    type="tel"
                    name="phone"
                    id={`${id}-phone`}
                    value={values.phone}
                    error={errors.phone && touched.phone && errors.phone}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  />

                  <Textarea
                    label={t('Message') + '*'}
                    name="message"
                    id={`${id}-message`}
                    value={values.message}
                    error={errors.message && touched.message && errors.message}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    required
                  />

                  <Checkbox
                    id={`${id}-privacy`}
                    name="privacy"
                    error={errors.privacy && touched.privacy && errors.privacy}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    required
                  >
                    <Trans i18nKey="terms_and_conditions">
                      I have read the MedSkin Solutions Dr. Suwelack AG
                      <a
                        href={privacytermsLink}
                        target="_blank"
                        rel="noopener"
                        {...bem('note__link')}
                      >
                        privacy terms
                      </a>
                      and agree to them.
                    </Trans>
                  </Checkbox>

                  <small {...bem('note')}>
                    <Trans i18nKey="contact_mandatory_fields">
                      Fields with an asterisk(*) are required.
                    </Trans>
                    <br />
                    <br />
                    <Trans i18nKey="legal_note_netlify">
                      We use Netlify as our hosting provider. By clicking below
                      to submit, you acknowledge that your information will be
                      transferred to Netlify for processing.
                      <a
                        href="https://www.netlify.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        {...bem('note__link')}
                      >
                        Learn more about Netlify privacy policy here
                      </a>
                    </Trans>
                  </small>

                  <Button
                    {...bem('submit')}
                    type="submit"
                    title={t('Click here to submit you message')}
                    disabled={!isValid || isSubmitting}
                  >
                    {t('Send message')}
                  </Button>
                </Form>
              )}
          </>
        )}
      </Formik>
    </Content>
  );
};

Contact.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  subject: string.isRequired,
};

export const fragment = graphql`
  fragment ContactContentFragment on CraftGQL_pageContents_contact_BlockType {
    id
    headline
    subject
  }
`;
